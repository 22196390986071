import React, { Component } from 'react'
import DefaultFrame from '../../components/DefaultFrame'
import NestedGenericTable from '../../components/GenericTable/NestedGenericTable'
import { APIData } from '../../constants/APIAdresses'
import { ALERT_SEVERITY, AlertDisplay2 } from '../../components/AlertDisplay2'
import { GD_MODE, GD_STATUS_TYPE, GENERIC_DRAWER_TYPE, GenericDrawer } from '../../components/GenericDrawer'
import { AddBusiness, DomainAdd, Save } from '@mui/icons-material'
import { withNavigate } from '../../hookInjection/withNavigate'
import { ROUTES } from '../..'

const mainColumns = [
    {
        width: 50,
        label: 'ID',
        dataKey: 'id',
    },
    {
        // width: 2,
        width: 200,
        label: 'Firma',
        dataKey: 'companyName',
    },
    {
        // width:2,
        width: 200,
        label: 'Inhaber',
        dataKey: 'ownerName',
    },
    {
        width: 200,
        label: 'Straße',
        dataKey: 'street',
    },
    {
        // width: 4,
        width: 50,
        label: 'Hausnummer',
        dataKey: 'houseNumber',
    },
    {
        // width: 3,
        width: 150,
        label: 'Ort',
        dataKey: 'city',
    },
    {
        width: 50,
        label: 'PLZ',
        dataKey: 'zip',
    },
    {
        width: 80,
        label: 'Telefon',
        dataKey: 'phone',
    },
    {
        width: 80,
        label: 'Fax',
        dataKey: 'fax',
    },
    {
        width: 200,
        label: 'E-Mail',
        dataKey: 'mailAddress',
    },
]
const subColumns = [
    {
        width: 100,
        headerName: 'ID',
        field: 'id',
    },
    {
        // width: 2,
        flex: 1,
        headerName: 'Name',
        field: 'name',
    },
    {
        // width: 3,
        flex: 1,
        headerName: 'Straße',
        field: 'street',
    },
    {
        // width: 4,
        width: 100,
        headerName: 'Hausnummer',
        field: 'houseNumber',
    },
    {
        // width: 3,
        flex: 1,
        headerName: 'Ort',
        field: 'city',
    },
    {
        width: 80,
        headerName: 'PLZ',
        field: 'zip',
    },
    {
        flex: 1,
        headerName: 'Telefon',
        field: 'phone',
    },
    {
        flex: 1,
        headerName: 'E-Mail',
        field: 'mail',
    }
]
export class PartnerManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
        }
    }

    updateCompany(configData) {
        let body = {
            fields: {}
        }
        const showErr = () => {
            this.props.closeDrawer();
            this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
        }
        configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
            if (field.value?.length > 0 || field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                body.fields[field.dbKey] = field.value;
            }
        });
        console.log("COMPANY BODY: ", body);
        if (Object.keys(body.fields).length > 0) {
            fetch(APIData.SHOP + "/company/" + configData.id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (!response.ok) {
                        showErr();
                    } else {
                        let myData = [...this.state.tableData];
                        configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
                            let index = myData.findIndex(company => company.id === configData.id);
                            if (index != -1) {
                                myData[index][field.dbKey] = field.value;
                            }
                        })
                        this.setState({
                            tableData: [...myData]
                        })
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Partnerdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                    }
                }).catch(e => {
                    console.log("Error while updating company data: ", e);
                    showErr();
                })
        } else {
            this.props.closeDrawer();
            this.props.showAlertDisplay("Es wurden keine Änderungen vorgenommen.", ALERT_SEVERITY.INFO);
        }

    }
    addCompany(configData) {
        let body = {
            fields: {}
        }
        configData.fields.forEach((field) => {
            if (field.value?.length > 0) {
                body.fields[field.dbKey] = field.value;
            }
        });
        fetch(APIData.SHOP + "/company/add", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (response.ok) {
                    // WE GET AN PARSE ERROR HERE...??
                    return response.json();
                } else {
                    return null;
                }
            }).then(result => {
                if (result?.id) {
                    this.props.showAlertDisplay("Partner wurde erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                    let myData = [...this.state.tableData];
                    myData.push({ id: result.id, ...body.fields });
                    this.setState({ tableData: [...myData] });
                    this.props.closeDrawer();
                } else {
                    this.props.showAlertDisplay("Beim Hinzufügen vom Partner ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
                }
            }).catch(e => {
                console.log("Error while adding company: ", e);
            })
    }
    openNewCompanyDrawer() {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Hinzufügen",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addCompany(configData) }
                }
            ],
            fields: [
                {
                    label: "Firma",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "companyName",
                    isRequired: true
                }, {
                    label: "Inhaber",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "ownerName",
                    isRequired: true
                }, {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "street",
                    isRequired: true
                }, {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "houseNumber",
                    isRequired: true
                }, {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "city",
                    isRequired: true
                }, {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "zip",
                    isRequired: true,
                    isNumeric: true,
                    minChar: 5,
                    maxChar: 5,
                }, {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "mailAddress",
                    isMail: true,
                    isRequired: true
                }, {
                    label: "Steuernummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "taxId",
                    isRequired: true
                }, {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "phone",
                    isPhone: true
                }, {
                    label: "Fax",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "fax"
                }, {
                    label: "Amtsgericht",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "compotentCourtDescription"
                }, {
                    label: "Handelsregisternummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "compotentCourtId"
                }, {
                    label: "Umsatzsteuer-ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "vatId"
                }, {
                    label: "Bevollmächtigter / Vertretungsberechtigter",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "authorizedRepresentative"
                }, {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "additionalInfo"
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.ADD);
    }
    openCompanyEditDrawer(index) {
        let baseDrawerConfig = {
            id: this.state.tableData[index].id,
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateCompany(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: this.state.tableData[index].isActive,
                    dbKey: 'isActive'
                },
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: this.state.tableData[index].id,
                    dbKey: "id"
                }, {
                    label: "Firma",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].companyName,
                    dbKey: "companyName"
                },
                {
                    label: "Inhaber",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].ownerName,
                    dbKey: "ownerName"
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].street,
                    dbKey: "street"
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].houseNumber,
                    dbKey: "houseNumber"
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].city,
                    dbKey: "city"
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].zip,
                    dbKey: "zip",
                    isNumeric: true,
                    isRequired: true,
                    maxChar: 5
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].phone,
                    dbKey: "phone"
                },
                {
                    label: "Fax",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].fax,
                    dbKey: "fax"
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].mailAddress,
                    dbKey: "mailAddress"
                },
                {
                    label: "Amtsgericht",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].compotentCourtDescription,
                    dbKey: "compotentCourtDescription"
                },
                {
                    label: "Handelsregisternummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].compotentCourtId,
                    dbKey: "compotentCourtId"
                },
                {
                    label: "Steuer-ID.",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].taxId,
                    dbKey: "taxId"
                },
                {
                    label: "USt-IdNr.",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].vatId,
                    dbKey: "vatId"
                },
                {
                    label: "Bevollmächtigter / Vertretungsberechtigter",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].authorizedRepresentative,
                    dbKey: "authorizedRepresentative"
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: this.state.tableData[index].additionalInfo,
                    dbKey: "additionalInfo"
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    uploadImage(file, uploadType) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("image", file);
            fetch(APIData.MEDIA + "/upload?type=" + uploadType, { body: formData, method: "POST", credentials: "include" })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject("Error while uploading image");
                    }
                }).then(result => {
                    console.log("UPLOADRESULT: ", result);
                    if (result?.path) {
                        resolve(result.path);
                    } else {
                        reject("Error while uploading image");
                    }
                }).catch(e => {
                    reject("Error while uploading image: " + e);
                })
            // })
        })
    }
    checkUpdateImages(configData) {
        return new Promise((resolve, reject) => {
            let imgPromises = [];
            var updatedConfigData = { ...configData };
            configData.fields.forEach((field, index) => {
                // .filter(field => field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE)
                console.log("Going through fields: ", field);
                if (field.status === GD_STATUS_TYPE.UPDATE && field.type === GENERIC_DRAWER_TYPE.IMAGE) {
                    imgPromises.push(
                        this.uploadImage(field.value, field.uploadType).then(result => {
                            updatedConfigData.fields[index].value = result;
                        }).catch(e => {
                            console.log("Error while uploading image: ", e);
                        })
                    );
                }
            })
            console.log("Updating files: ", imgPromises);
            Promise.all(imgPromises).then(() => {
                resolve({ ...updatedConfigData });
            }).catch(e => {
                console.log("PROMISE_Error while updating images: ", e);
            })
        })
    }
    updateShop(configData) {
        this.checkUpdateImages(configData)
            .then(updatedConfigData => {
                console.log("UPDATEDCONFIGDATA: ", updatedConfigData);
                configData = { ...updatedConfigData };
                let body = {
                    fields: {}
                }
                let lat = "";
                let lon = "";
                const showErr = () => {
                    this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
                }
                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field, index) => {
                    if (field.value?.length > 0 || field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                        if (field.dbKey === "gpsPosition") {
                            if (field.label === "Breitengrad") {
                                lat = field.value;
                            } else {
                                lon = field.value;
                            }
                        } else {
                            body.fields[field.dbKey] = field.value;
                        }
                    }
                });
                if (lat.length > 0 && lon.length > 0) {
                    body.fields["gpsPosition"] = `POINT (${lat} ${lon})`;
                }
                console.log("SHOP BODY: ", body);
                if (Object.keys(body.fields).length > 0) {
                    fetch(APIData.SHOP + "/shop/" + configData.id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                        .then(response => {
                            if (!response.ok) {
                                showErr();
                            } else {
                                this.props.closeDrawer();
                                this.props.showAlertDisplay("Shopdaten wurden erfolgreich aktualisiert.", ALERT_SEVERITY.SUCCESS);
                                let myData = [...this.state.tableData];
                                configData.fields.filter(field => field.status === GD_STATUS_TYPE.UPDATE).forEach((field) => {
                                    let index = myData[configData.index].shops.findIndex(shop => shop.id === configData.id);
                                    if (index != -1) {
                                        if (field.dbKey === "gpsPosition") {
                                            if (field.label === "Breitengrad") {
                                                myData[configData.index].shops[index].lat = field.value;
                                            } else {
                                                myData[configData.index].shops[index].lon = field.value;
                                            }
                                        } else {
                                            myData[configData.index].shops[index][field.dbKey] = field.value;
                                        }
                                    }
                                })
                                this.setState({ tableData: [...myData] });
                                this.props.closeDrawer();
                            }
                        }).catch(e => {
                            console.log("Error while update shop \nbody: ", body, "\nerror: ", e);
                            showErr();
                        })
                } else {
                    this.props.closeDrawer();
                    this.props.showAlertDisplay("Es wurden keine Änderungen vorgenommen", ALERT_SEVERITY.INFO);
                }
            }).catch(e => {
                console.log("Error while update shop (checkUpdateImage): ", e);
            })
    }
    addShop(mainIndex, configData) {
        this.checkUpdateImages(configData).then(updatedConfigData => {
            configData = { ...updatedConfigData };
            console.log("Adding Shop (company=" + this.state.tableData[mainIndex].companyName + "): ", configData);
            let body = {
                fields: {}
            }
            let lon = "";
            let lat = "";
            configData.fields.forEach((field) => {
                if (field.value?.length > 0) {
                    if (field.dbKey === "gpsPosition") {
                        if (field.label === "Breitengrad") {
                            lat = field.value;
                        } else {
                            lon = field.value;
                        }
                    } else {
                        body.fields[field.dbKey] = field.value;
                    }
                }
                if (field.type === GENERIC_DRAWER_TYPE.SWITCH) {
                    body.fields[field.dbKey] = field.value;
                }
            });
            body.fields["companyId"] = this.state.tableData[mainIndex].id;
            body.fields["gpsPosition"] = `POINT (${lat} ${lon})`;
            console.log("SHOP BODY: ", body);
            const showErr = () => {
                this.props.closeDrawer();
                this.props.showAlertDisplay("Beim Hinzufügen vom Shop ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
            }
            fetch(APIData.SHOP + "/shop/add", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    if (response.ok) {
                        this.props.closeDrawer();
                        this.props.showAlertDisplay("Shop wurde erfolgreich hinzugefügt.", ALERT_SEVERITY.SUCCESS);
                        // return response.json();
                    } else {
                        showErr();
                        return null;
                    }
                }).catch(e => {
                    console.log("Error while adding shop: ", e);
                    showErr();
                })
        }).catch(e => {
            console.log("Error while update shop (checkUpdateImage): ", e);
        })
    }
    openNewShopDrawer(mainIndex) {
        let baseDrawerConfig = {
            options: [
                {
                    label: "Hinzufügen",
                    icon: <Save />,
                    doRequiredFieldCheck: true,
                    onClick: (configData) => { this.addShop(mainIndex, configData) }
                }
            ],
            fields: [
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.HEADERS,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Logo",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: "",
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.ICONS,
                    dimensions: { width: 500, height: 500 },
                    dbKey: "iconSource"
                },
                {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "name",
                    isRequired: true
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "street",
                    isRequired: true
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "houseNumber",
                    isRequired: true
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "city",
                    isRequired: true
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "zip",
                    isRequired: true
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "phone",
                    isRequired: true
                },
                {
                    label: "GPS-Koordinaten",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "Breitengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "gpsPosition",
                    // maxChar: 8, 
                    isRequired: true
                },
                {
                    label: "Längengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "gpsPosition",
                    // maxChar: 7,
                    isRequired: true
                },
                {
                    label: "",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "mail"
                },
                {
                    label: "Lieferzeit",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    isNumeric: true,
                    value: "",
                    dbKey: "deliveryTime"
                },
                {
                    label: "Abholrabatt",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "pickupDiscount",
                    isNumeric: true,
                    maxChar: 2,
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: "",
                    dbKey: "additionalInfo"
                },
                {
                    label: "Kartenzahlung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "debitAvailable"
                },
                {
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    label: "Zahlungen Deaktiviert",
                    value: false,
                    dbKey: "paymentsDisabled"
                },
                {
                    label: "Großhandelshop",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "partnerDealShop"
                },
                {
                    label: "Automatische Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "extDeliveryByDefault"
                },
                {
                    label: "Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: false,
                    dbKey: "extDeliveryActive"
                }
            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.ADD);
    }
    openShopEditDrawer(index, shop) {
        let baseDrawerConfig = {
            id: shop.id,
            index: index,
            options: [
                { label: "Speichern", onClick: (configData) => { this.updateShop(configData) } },
                { label: "Abbrechen", onClick: () => { this.props.closeDrawer() } }
            ],
            fields: [
                {
                    label: "Aktiv",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.isActive,
                },
                {
                    label: "Titelbild",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: shop.imgSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.HEADERS,
                    dimensions: { width: 800, height: 200 },
                    dbKey: "imgSource"
                },
                {
                    label: "Logo",
                    type: GENERIC_DRAWER_TYPE.IMAGE,
                    value: shop.iconSource,
                    baseUri: APIData.MEDIA,
                    uploadType: APIData.MEDIA_TYPES.ICONS,
                    dimensions: { width: 500, height: 500 },
                    dbKey: "iconSource"
                },
                {
                    label: "ID",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    disabled: true,
                    value: shop.id,
                    dbKey: "id"
                }, {
                    label: "Name",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.name,
                    dbKey: "name"
                },
                {
                    label: "Straße",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.street,
                    dbKey: "street"
                },
                {
                    label: "Hausnummer",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.houseNumber,
                    dbKey: "houseNumber"
                },
                {
                    label: "Ort",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.city,
                    dbKey: "city"
                },
                {
                    label: "PLZ",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.zip,
                    dbKey: "zip"
                },
                {
                    label: "Telefon",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.phone,
                    dbKey: "phone"
                },
                {
                    label: "GPS-Koordinaten",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "Breitengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.lat,
                    dbKey: "gpsPosition",
                    maxChar:8,
                    isRequired: true
                },
                {
                    label: "Längengrad",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.lon,
                    dbKey: "gpsPosition",
                    maxChar:7,
                    isRequired: true
                },
                {
                    label: "",
                    type: GENERIC_DRAWER_TYPE.DIVIDER,
                },
                {
                    label: "E-Mail",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.mail,
                    dbKey: "mail"
                },
                {
                    label: "Zusatzinfo",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.additionalInfo,
                    dbKey: "additionalInfo"
                },
                {
                    label: "Abholrabatt",
                    type: GENERIC_DRAWER_TYPE.TEXT,
                    value: shop.pickupDiscount,
                    dbKey: "pickupDiscount"
                },
                {
                    label: "Kartenzahlung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.debitAvailable,
                    dbKey: "debitAvailable"
                },
                {
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    label: "Zahlungen Deaktiviert",
                    value: shop.paymentsDisabled,
                    dbKey: "paymentsDisabled"
                },
                {
                    label: "Großhandelshop",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.partnerDealShop,
                    dbKey: "partnerDealShop"
                },
                {
                    label: "Automatische Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.extDeliveryByDefault,
                    dbKey: "extDeliveryByDefault"
                },
                {
                    label: "Externe Lieferung",
                    type: GENERIC_DRAWER_TYPE.SWITCH,
                    value: shop.extDeliveryActive,
                    dbKey: "extDeliveryActive"
                }

            ]
        }
        this.props.openDrawer(baseDrawerConfig, GD_MODE.UPDATE);
    }
    setCompanyActive(index, active) {
        let myData = [...this.state.tableData];
        myData[index].isActive = active;
        this.setState({ tableData: [...myData] });
        let body = {
            fields: {
                isActive: active
            }
        }
        const toggleCompanyActive = () => {
            myData[index].isActive = !active;
            this.setState({
                tableData: [...myData],
            });
            this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
        }
        fetch(APIData.SHOP + "/company/" + myData[index].id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) {
                    console.log("Error while updating company data: ", response);
                    toggleCompanyActive()
                }
            }).catch(e => {
                console.log("Error while updating company data: ", e);
                toggleCompanyActive();
            })
    }
    setShopActive(mainIndex, row) {
        let myData = [...this.state.tableData];
        let shopActive = !row.isActive;
        myData[mainIndex].shops = myData[mainIndex].shops.map((shop) => {
            if (shop.id === row.id) {
                shop.isActive = !shop.isActive;
                shopActive = shop.isActive;
            }
            return shop;
        });
        this.setState({ tableData: [...myData] });
        let body = {
            fields: {
                isActive: shopActive
            }
        }
        const toggleShopActive = () => {
            myData[mainIndex].shops = myData[mainIndex].shops.map((shop) => {
                if (shop.id === row.id) {
                    shop.isActive = !shopActive;
                }
                return shop;
            });
            this.setState({
                tableData: [...myData],
            });
            this.props.showAlertDisplay("Beim Aktualisieren der Daten ist ein Fehler aufgetreten.", ALERT_SEVERITY.ERROR);
        }
        fetch(APIData.SHOP + "/shop/" + row.id + "/updateInformation", { body: JSON.stringify(body), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                if (!response.ok) {
                    console.log("Error while updating shop data: ", response);
                    toggleShopActive()
                }
            }).catch(e => {
                console.log("Error while updating shop data: ", e);
                toggleShopActive();
            })
    }
    render() {
        return (
            <>
                <NestedGenericTable
                    config={{ columns: [...mainColumns], subColumns: [...subColumns], subTableKey: "shops" }}
                    apiEndPoint={APIData.SHOP + "/company/getPagedCompanyData"}
                    tableData={this.state.tableData}
                    defaultItemsPerPage={25}
                    setTableData={(data) => { this.setState({ tableData: data }) }}
                    onClickRow={(index) => {
                        console.log("MainRow clicked redirect to: ./company/" + this.state.tableData[index].id);
                        this.props.navigate(ROUTES.PARTNER(this.state.tableData[index].id));
                    }}
                    getSubRowId={(row) => { console.log("####--- GETSUBROWID OF ROW: ",row); return row.id }}
                    addBtnOnClick={() => { this.openNewCompanyDrawer() }}
                    addBtnLabel="Partner Hinzufügen"
                    activeKey="isActive"
                    addBtnIcon={<DomainAdd />}
                    addSubBtnIcon={<AddBusiness />}
                    onSetActive={(index, active) => {
                        this.setCompanyActive(index, active);
                    }}
                    onEdit={(index) => {
                        console.log("ONEDIT CLICKED: ", this.state.tableData[index]);
                        this.openCompanyEditDrawer(index);
                    }}
                    onDelete={(index) => {
                        console.log("ONDELETE CLICKED: ", this.state.tableData[index]);
                    }}

                    onClickSubRow={(mainIndex, row) => {
                        console.log("Subrow clicked redirect to ./company/" + this.state.tableData[mainIndex].id + "/shop/" + row.id);
                        this.props.navigate(ROUTES.SHOP(this.state.tableData[mainIndex].id, row.id))

                    }}
                    // onDuplicate={(index, id) => {
                    //     console.log("DUPLICATE COMPANY!!!: ", index, " ID: ",id);
                    // }}
                    onSubDublicate={(mainIndex, subrow, row) => {
                        console.log("DUPLICATE SHOP!!!:  ",mainIndex," SUBINDEX: ",subrow, " ROW: ",row);
                        fetch(APIData.SHOP + "/shop/" + subrow.id + "/duplicate", {credentials:"include"})
                        .then(response => {
                            console.log("DuplicateResponse: ", response);
                            if(response.ok){
                                console.log("Shop duplicated successfully");
                            }
                            else{
                                console.log("Error while duplicating shop: ", response.status);
                            }
                        }).catch(e => {
                            console.log("Error while duplicating shop: ", e);

                        })

                    }}
                    addSubBtnLabel="Shop Hinzufügen"
                    addSubBtnOnClick={(index) => {
                        console.log("Add Sub Button Clicked: ", index);
                        this.openNewShopDrawer(index);
                    }}
                    onSubSetActive={(mainIndex, row) => {
                        this.setShopActive(mainIndex, row);
                    }}
                    subActiveKey="isActive"
                    onSubEdit={(mainIndex, row) => {
                        this.openShopEditDrawer(mainIndex, row)
                    }}
                    onSubDelete={(mainIndex, row) => {

                    }}
                />
            </>
        )
    }
}
class defaultWrapper extends React.Component {
    render() {
        return (
            <DefaultFrame title='Partnermanagement'>
                <PartnerManagement {...this.props} />
            </DefaultFrame>
        )
    }
}
export default withNavigate(defaultWrapper);
{/* <GenericDrawer mode={GD_MODE.UPDATE} config={this.state.drawerConfigs} isOpen={this.state.drawerOpen} closeDrawer={() => { this.setState({ drawerOpen: false }) }} openDrawer={() => { this.setState({ drawerOpen: true }); }} />
                <AlertDisplay2
                    severity={this.state.alertSeverity}
                    label={this.state.alertDisplayMsg}
                    isVisible={this.state.alertDisplayVisible}
                    hide={() => { this.setState({ alertDisplayVisible: false }) }} /> */}