import React, { Component } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Button, IconButton, Switch, TextField } from '@mui/material';
import { Add, ArrowDownward, ArrowUpward, ContentCopy, Delete, Edit } from '@mui/icons-material';

export class PagedDataGrid extends Component {
    constructor(props) {
        super(props);
        console.log("GETUNIQUEROWID: ", this.props.getSubRowId);
        this.myColumns = this.props.columns ? [...this.props.columns] : [];
        var myRows = props.rows ? [...props.rows] : []
        if (props.activeKey != null && props.onSetActive != null) {
            this.myColumns.unshift({
                field: 'isActive',
                headerName: 'Aktiv',
                width: 80,
                renderCell: (params) => {
                    return <Switch size='small' color="primary"
                        checked={props.subIsActive ? props.subIsActive(params.row) : params.row[props.activeKey]}
                        onClick={(event) => { event.stopPropagation(); props.subIsActive ? props.onSetActive(!props.subIsActive(params.row)) : props.onSetActive(params.row); }} />
                }
            });
        }


        if (props.onEdit != null || props.onDelete != null || props.onDuplicate != null) {
            this.myColumns.push({
                field: 'edit',
                headerName: '',
                width: 120,
                renderCell: (params) => {
                    return (
                        <div onClick={(e) => { e.stopPropagation(); }} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: 52 }}>
                            {props.onDelete != null &&
                                <IconButton color='primary' onClick={(e) => { e.stopPropagation(); props.onDelete(params.row) }}><Delete /></IconButton>
                            }
                            {
                                props.onDuplicate != null &&
                                <IconButton color='primary' onClick={(e) => { e.stopPropagation(); props.onDuplicate(params.row) }}><ContentCopy /></IconButton>
                            }
                            {
                                props.onEdit != null &&
                                <IconButton color='primary' onClick={(e) => { e.stopPropagation(); props.onEdit(params.row) }}><Edit /></IconButton>
                            }
                        </div>
                    )
                }
            });
        }
        if (props.onSubPositionChange != null) {
            this.myColumns.push({
                field: 'positionChange',
                headerName: '',
                width: 100,
                renderCell: (params) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <IconButton color='primary' size='small' onClick={(e) => { e.stopPropagation(); props.onSubPositionChange(params, -1) }}><ArrowUpward style={{ width: 20, height: 20 }} /></IconButton>
                            <IconButton color='primary' size='small' onClick={(e) => { e.stopPropagation(); props.onSubPositionChange(params, 1) }}><ArrowDownward style={{ width: 20, height: 20 }} /></IconButton>
                        </div>
                    )
                }
            });
        }

        this.state = {
            rows: myRows
        }
        console.log("PDGROWS: ", this.props.rows);
        console.log("PDGCOLS: ", this.props.columns);
        console.log("Current rows in state: ", this.state.rows);
    }
    render() {
        return (
            <div style={{ padding: 20 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                        <TextField sx={{ width: "50%" }} size='small' label="Suche" variant="outlined" onChange={(evt) => {
                            let myRows = [...this.props.rows];
                            let searchValue = evt.target.value;
                            let filteredRows = myRows.filter((row) => {
                                let found = false;
                                for (let key in row) {
                                    if (row[key] && row[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                                        found = true;
                                        break;
                                    }
                                }
                                return found;
                            });
                            this.setState({ rows: [...filteredRows] });
                        }} />
                    </div>
                    {!this.props.noAdd &&
                        <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
                            <Button onClick={() => { this.props.addBtnOnClick() }}
                                startIcon={this.props.addBtnIcon ? this.props.addBtnIcon : this.props.addBtnLabel && <Add />} variant='outlined' style={{ height: 40 }}>{this.props.addBtnLabel ? this.props.addBtnLabel : this.props.addBtnIcon ? this.props.addBtnIcon : <Add />}</Button>
                        </div>
                    }
                </div>
                <DataGrid

                    getRowId={(row) => {
                        console.log("####---GETROWID fn: ", this.props.getSubRowId);
                        console.log("####---GETROWID: ", this.props.getSubRowId(row));
                        console.log("####---ROW AT PAGEDDATAGRID:: ", row);
                        console.log("####---ROW TYPE AT PAGEDDATAGRID:: ", typeof row);

                         return this.props.getSubRowId(row) 
                        }}
                    disableRowSelectionOnClick
                    rows={this.state.rows}
                    onRowClick={(params) => { this.props.onClick(params.row) }}
                    style={{ height: 400, width: '100%' }}
                    columns={this.myColumns}
                    initialState={{ pagination: { page: 0, pageSize: 5 }, sorting: { sortModel: this.props.subTableSortModel } }}
                    pageSizeOptions={[5, 10]}

                    sx={{
                        border: "0.5px solid #CECECE", marginTop: "20px",
                        "& .MuiDataGrid-row.Mui-selected": {
                            backgroundColor: "inherit", // Remove the background color on row selection
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none", // Remove the outline that appears when a cell is focused
                        },
                        "& .MuiDataGrid-row:focus-within": {
                            outline: "none", // Remove the outline that appears around a row
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none", // Remove the outline around a cell on click
                        },
                    }}
                />
            </div>
        )
    }
}

export default PagedDataGrid